@tailwind components;
@tailwind utilities;

:root {
  --appbar-h: 64px;
}

html,
body {
  padding: 0 !important;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

* {
  box-sizing: border-box !important;
}

/* stylelint-disable selector-id-pattern, selector-class-pattern */
html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  @apply h-full bg-bluegray-10 !important;
}

.react-datepicker__month--selected,
.react-datepicker__day--selected {
  /* prettier-ignore */
  @apply text-white bg-orange-50 !important;
}

.react-datepicker__month-text--keyboard-selected:not(.react-datepicker__month--selected) {
  background-color: unset !important;
  color: unset !important;
}

.react-datepicker__day--outside-month {
  visibility: hidden !important;
}
