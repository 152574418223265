.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
.pointer-events-none {
  pointer-events: none !important;
}
.fixed {
  position: fixed !important;
}
.absolute {
  position: absolute !important;
}
.relative {
  position: relative !important;
}
.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.inset-1 {
  top: 0.25rem !important;
  right: 0.25rem !important;
  bottom: 0.25rem !important;
  left: 0.25rem !important;
}
.-bottom-9 {
  bottom: -2.25rem !important;
}
.right-0 {
  right: 0px !important;
}
.bottom-4 {
  bottom: 1rem !important;
}
.right-4 {
  right: 1rem !important;
}
.top-16 {
  top: 4rem !important;
}
.top-\[135px\] {
  top: 135px !important;
}
.right-1 {
  right: 0.25rem !important;
}
.top-1 {
  top: 0.25rem !important;
}
.top-0 {
  top: 0px !important;
}
.left-1\/2 {
  left: 50% !important;
}
.bottom-2\.5 {
  bottom: 0.625rem !important;
}
.bottom-2 {
  bottom: 0.5rem !important;
}
.top-6 {
  top: 1.5rem !important;
}
.left-0 {
  left: 0px !important;
}
.-bottom-4 {
  bottom: -1rem !important;
}
.bottom-0 {
  bottom: 0px !important;
}
.right-2 {
  right: 0.5rem !important;
}
.top-\[28px\] {
  top: 28px !important;
}
.-right-2\.5 {
  right: -0.625rem !important;
}
.-right-2 {
  right: -0.5rem !important;
}
.-bottom-2 {
  bottom: -0.5rem !important;
}
.-z-10 {
  z-index: -10 !important;
}
.z-30 {
  z-index: 30 !important;
}
.z-10 {
  z-index: 10 !important;
}
.col-span-2 {
  grid-column: span 2 / span 2 !important;
}
.col-span-full {
  grid-column: 1 / -1 !important;
}
.col-span-5 {
  grid-column: span 5 / span 5 !important;
}
.col-span-3 {
  grid-column: span 3 / span 3 !important;
}
.col-start-2 {
  grid-column-start: 2 !important;
}
.col-start-1 {
  grid-column-start: 1 !important;
}
.col-start-7 {
  grid-column-start: 7 !important;
}
.col-start-8 {
  grid-column-start: 8 !important;
}
.col-start-9 {
  grid-column-start: 9 !important;
}
.row-start-2 {
  grid-row-start: 2 !important;
}
.row-start-1 {
  grid-row-start: 1 !important;
}
.float-left {
  float: left !important;
}
.m-3 {
  margin: 0.75rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.ml-4 {
  margin-left: 1rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.ml-5 {
  margin-left: 1.25rem !important;
}
.mr-7 {
  margin-right: 1.75rem !important;
}
.mr-10 {
  margin-right: 2.5rem !important;
}
.ml-3 {
  margin-left: 0.75rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mb-8 {
  margin-bottom: 2rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mb-5 {
  margin-bottom: 1.25rem !important;
}
.mr-4 {
  margin-right: 1rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mr-3 {
  margin-right: 0.75rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mt-px {
  margin-top: 1px !important;
}
.mr-6 {
  margin-right: 1.5rem !important;
}
.mr-5 {
  margin-right: 1.25rem !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.ml-12 {
  margin-left: 3rem !important;
}
.mt-5 {
  margin-top: 1.25rem !important;
}
.ml-10 {
  margin-left: 2.5rem !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mt-6 {
  margin-top: 1.5rem !important;
}
.mt-8 {
  margin-top: 2rem !important;
}
.ml-8 {
  margin-left: 2rem !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.flex {
  display: flex !important;
}
.table {
  display: table !important;
}
.table-row {
  display: table-row !important;
}
.grid {
  display: grid !important;
}
.hidden {
  display: none !important;
}
.h-\[var\(--appbar-h\)\] {
  height: var(--appbar-h) !important;
}
.h-8 {
  height: 2rem !important;
}
.h-\[calc\(100vh-var\(--appbar-h\)-292px\)\] {
  height: calc(100vh - var(--appbar-h) - 292px) !important;
}
.h-\[60px\] {
  height: 60px !important;
}
.h-16 {
  height: 4rem !important;
}
.h-9 {
  height: 2.25rem !important;
}
.h-32 {
  height: 8rem !important;
}
.h-10 {
  height: 2.5rem !important;
}
.h-auto {
  height: auto !important;
}
.h-\[88px\] {
  height: 88px !important;
}
.h-\[224px\] {
  height: 224px !important;
}
.h-\[72px\] {
  height: 72px !important;
}
.h-7 {
  height: 1.75rem !important;
}
.h-5 {
  height: 1.25rem !important;
}
.h-12 {
  height: 3rem !important;
}
.h-\[calc\(80vh-12rem\)\] {
  height: calc(80vh - 12rem) !important;
}
.h-\[calc\(100vh-var\(--appbar-h\)-115px-138px-12px-72px\)\] {
  height: calc(100vh - var(--appbar-h) - 115px - 138px - 12px - 72px) !important;
}
.h-2 {
  height: 0.5rem !important;
}
.h-3 {
  height: 0.75rem !important;
}
.h-full {
  height: 100% !important;
}
.h-0 {
  height: 0px !important;
}
.h-4 {
  height: 1rem !important;
}
.h-\[84px\] {
  height: 84px !important;
}
.h-\[49px\] {
  height: 49px !important;
}
.h-\[calc\(100vh-var\(--appbar-h\)-115px-28px-16px\)\] {
  height: calc(100vh - var(--appbar-h) - 115px - 28px - 16px) !important;
}
.h-\[1rem\] {
  height: 1rem !important;
}
.h-\[calc\(80vh-14rem\)\] {
  height: calc(80vh - 14rem) !important;
}
.h-6 {
  height: 1.5rem !important;
}
.h-\[104px\] {
  height: 104px !important;
}
.h-\[74px\] {
  height: 74px !important;
}
.h-\[22px\] {
  height: 22px !important;
}
.h-\[40px\] {
  height: 40px !important;
}
.h-60 {
  height: 15rem !important;
}
.h-36 {
  height: 9rem !important;
}
.max-h-\[400px\] {
  max-height: 400px !important;
}
.max-h-\[calc\(100vh-411px\)\] {
  max-height: calc(100vh - 411px) !important;
}
.max-h-96 {
  max-height: 24rem !important;
}
.min-h-\[210px\] {
  min-height: 210px !important;
}
.min-h-\[128px\] {
  min-height: 128px !important;
}
.min-h-0 {
  min-height: 0px !important;
}
.min-h-\[-var\(--appbar-h\)\] {
  min-height: -var(--appbar-h) !important;
}
.min-h-\[24px\] {
  min-height: 24px !important;
}
.min-h-\[20px\] {
  min-height: 20px !important;
}
.w-\[614px\] {
  width: 614px !important;
}
.w-full {
  width: 100% !important;
}
.w-\[273px\] {
  width: 273px !important;
}
.w-\[980px\] {
  width: 980px !important;
}
.w-32 {
  width: 8rem !important;
}
.w-60 {
  width: 15rem !important;
}
.w-52 {
  width: 13rem !important;
}
.w-48 {
  width: 12rem !important;
}
.w-16 {
  width: 4rem !important;
}
.w-\[600px\] {
  width: 600px !important;
}
.w-\[492px\] {
  width: 492px !important;
}
.w-\[512px\] {
  width: 512px !important;
}
.w-56 {
  width: 14rem !important;
}
.w-7 {
  width: 1.75rem !important;
}
.w-5 {
  width: 1.25rem !important;
}
.w-2 {
  width: 0.5rem !important;
}
.w-9 {
  width: 2.25rem !important;
}
.w-8 {
  width: 2rem !important;
}
.w-3 {
  width: 0.75rem !important;
}
.w-\[330px\] {
  width: 330px !important;
}
.w-\[2px\] {
  width: 2px !important;
}
.w-96 {
  width: 24rem !important;
}
.w-1\/12 {
  width: 8.333333% !important;
}
.w-11\/12 {
  width: 91.666667% !important;
}
.w-40 {
  width: 10rem !important;
}
.w-64 {
  width: 16rem !important;
}
.w-\[216px\] {
  width: 216px !important;
}
.w-\[420px\] {
  width: 420px !important;
}
.w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.w-24 {
  width: 6rem !important;
}
.w-20 {
  width: 5rem !important;
}
.w-\[49px\] {
  width: 49px !important;
}
.w-10 {
  width: 2.5rem !important;
}
.w-\[618px\] {
  width: 618px !important;
}
.w-\[560px\] {
  width: 560px !important;
}
.w-1 {
  width: 0.25rem !important;
}
.w-1\/4 {
  width: 25% !important;
}
.w-2\/3 {
  width: 66.666667% !important;
}
.w-auto {
  width: auto !important;
}
.w-\[140px\] {
  width: 140px !important;
}
.w-36 {
  width: 9rem !important;
}
.w-\[144px\] {
  width: 144px !important;
}
.w-28 {
  width: 7rem !important;
}
.w-6 {
  width: 1.5rem !important;
}
.w-4 {
  width: 1rem !important;
}
.w-\[6px\] {
  width: 6px !important;
}
.w-\[50px\] {
  width: 50px !important;
}
.w-\[200px\] {
  width: 200px !important;
}
.min-w-\[200px\] {
  min-width: 200px !important;
}
.min-w-\[230px\] {
  min-width: 230px !important;
}
.min-w-\[256px\] {
  min-width: 256px !important;
}
.min-w-\[3rem\] {
  min-width: 3rem !important;
}
.min-w-0 {
  min-width: 0px !important;
}
.min-w-\[135px\] {
  min-width: 135px !important;
}
.min-w-\[90px\] {
  min-width: 90px !important;
}
.min-w-\[38px\] {
  min-width: 38px !important;
}
.max-w-\[506px\] {
  max-width: 506px !important;
}
.max-w-\[135px\] {
  max-width: 135px !important;
}
.max-w-\[6rem\] {
  max-width: 6rem !important;
}
.max-w-\[11\.5rem\] {
  max-width: 11.5rem !important;
}
.max-w-full {
  max-width: 100% !important;
}
.flex-1 {
  flex: 1 1 0% !important;
}
.flex-none {
  flex: none !important;
}
.shrink {
  flex-shrink: 1 !important;
}
.flex-grow {
  flex-grow: 1 !important;
}
.flex-grow-\[1\] {
  flex-grow: 1 !important;
}
.translate-x-12 {
  --tw-translate-x: 3rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.scale-x-75 {
  --tw-scale-x: .75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}
.resize-none {
  resize: none !important;
}
.grid-flow-col {
  grid-auto-flow: column !important;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.grid-cols-\[1fr\2c auto\] {
  grid-template-columns: 1fr auto !important;
}
.grid-cols-\[660px\2c minmax\(430px\2c auto\)\2c 290px\] {
  grid-template-columns: 660px minmax(430px,auto) 290px !important;
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}
.grid-cols-\[80px\2c 1fr\] {
  grid-template-columns: 80px 1fr !important;
}
.grid-cols-\[200px\2c 1fr\2c 1fr\] {
  grid-template-columns: 200px 1fr 1fr !important;
}
.grid-rows-\[24px\2c calc\(100vh-var\(--appbar-h\)-115px-36px-88px\)\2c 88px\] {
  grid-template-rows: 24px calc(100vh - var(--appbar-h) - 115px - 36px - 88px) 88px !important;
}
.flex-col {
  flex-direction: column !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.place-content-center {
  place-content: center !important;
}
.items-start {
  align-items: flex-start !important;
}
.items-end {
  align-items: flex-end !important;
}
.items-center {
  align-items: center !important;
}
.items-baseline {
  align-items: baseline !important;
}
.justify-start {
  justify-content: flex-start !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-between {
  justify-content: space-between !important;
}
.justify-around {
  justify-content: space-around !important;
}
.justify-items-start {
  justify-items: start !important;
}
.gap-4 {
  gap: 1rem !important;
}
.gap-10 {
  gap: 2.5rem !important;
}
.gap-8 {
  gap: 2rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-5 {
  gap: 1.25rem !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-3 {
  gap: 0.75rem !important;
}
.gap-6 {
  gap: 1.5rem !important;
}
.gap-x-1 {
  -moz-column-gap: 0.25rem !important;
       column-gap: 0.25rem !important;
}
.gap-x-10 {
  -moz-column-gap: 2.5rem !important;
       column-gap: 2.5rem !important;
}
.place-self-center {
  place-self: center !important;
}
.self-start {
  align-self: flex-start !important;
}
.self-end {
  align-self: flex-end !important;
}
.self-center {
  align-self: center !important;
}
.justify-self-center {
  justify-self: center !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-y-clip {
  overflow-y: clip !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}
.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.overflow-ellipsis {
  text-overflow: ellipsis !important;
}
.text-ellipsis {
  text-overflow: ellipsis !important;
}
.whitespace-normal {
  white-space: normal !important;
}
.whitespace-nowrap {
  white-space: nowrap !important;
}
.whitespace-pre-line {
  white-space: pre-line !important;
}
.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}
.break-words {
  overflow-wrap: break-word !important;
}
.rounded-full {
  border-radius: 9999px !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.rounded-none {
  border-radius: 0px !important;
}
.rounded-md {
  border-radius: 0.375rem !important;
}
.rounded-xl {
  border-radius: 0.75rem !important;
}
.rounded-r {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.border {
  border-width: 1px !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-0 {
  border-width: 0px !important;
}
.border-b {
  border-bottom-width: 1px !important;
}
.border-r {
  border-right-width: 1px !important;
}
.border-t {
  border-top-width: 1px !important;
}
.border-l {
  border-left-width: 1px !important;
}
.border-l-\[3px\] {
  border-left-width: 3px !important;
}
.border-b-2 {
  border-bottom-width: 2px !important;
}
.border-t-0 {
  border-top-width: 0px !important;
}
.border-b-0 {
  border-bottom-width: 0px !important;
}
.border-l-0 {
  border-left-width: 0px !important;
}
.border-r-0 {
  border-right-width: 0px !important;
}
.border-solid {
  border-style: solid !important;
}
.border-gray-40 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}
.border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}
.border-orange-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 152 0 / var(--tw-border-opacity)) !important;
}
.border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}
.border-gray-30 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}
.border-inherit {
  border-color: inherit !important;
}
.border-gray-60 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(197 197 197 / var(--tw-border-opacity)) !important;
}
.border-gray-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(204 204 204 / var(--tw-border-opacity)) !important;
}
.border-current {
  border-color: currentColor !important;
}
.border-gray-70 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(153 153 153 / var(--tw-border-opacity)) !important;
}
.border-b-gray-40 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}
.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.bg-orange-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 152 0 / var(--tw-bg-opacity)) !important;
}
.bg-gray-40 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity)) !important;
}
.bg-brown-20 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(232 226 217 / var(--tw-bg-opacity)) !important;
}
.bg-gray-30 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}
.bg-orange-10 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 250 242 / var(--tw-bg-opacity)) !important;
}
.bg-red-40 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 115 115 / var(--tw-bg-opacity)) !important;
}
.bg-blue-40 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(100 181 246 / var(--tw-bg-opacity)) !important;
}
.bg-gray-70 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(153 153 153 / var(--tw-bg-opacity)) !important;
}
.bg-yellow-20 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 240 161 / var(--tw-bg-opacity)) !important;
}
.bg-bluegray-10 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 246 250 / var(--tw-bg-opacity)) !important;
}
.bg-orange-20 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 244 226 / var(--tw-bg-opacity)) !important;
}
.bg-orange-40 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 173 51 / var(--tw-bg-opacity)) !important;
}
.bg-blue-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(227 242 253 / var(--tw-bg-opacity)) !important;
}
.bg-red-20 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 236 235 / var(--tw-bg-opacity)) !important;
}
.bg-gray-10 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 252 252 / var(--tw-bg-opacity)) !important;
}
.bg-gray-60 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(197 197 197 / var(--tw-bg-opacity)) !important;
}
.bg-brown-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(173 164 149 / var(--tw-bg-opacity)) !important;
}
.bg-brown-70 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(131 114 87 / var(--tw-bg-opacity)) !important;
}
.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(68 68 68 / var(--tw-bg-opacity)) !important;
}
.bg-gray-110 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(51 51 51 / var(--tw-bg-opacity)) !important;
}
.bg-white\/80 {
  background-color: rgb(255 255 255 / 0.8) !important;
}
.bg-pink-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity)) !important;
}
.p-16 {
  padding: 4rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.p-8 {
  padding: 2rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-0 {
  padding: 0px !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.py-2\.5 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}
.py-\[8\.5px\] {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
}
.pt-16 {
  padding-top: 4rem !important;
}
.pb-5 {
  padding-bottom: 1.25rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.pt-10 {
  padding-top: 2.5rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.pt-\[42px\] {
  padding-top: 42px !important;
}
.pb-\[17px\] {
  padding-bottom: 17px !important;
}
.pl-\[64px\] {
  padding-left: 64px !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 0.75rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pl-3 {
  padding-left: 0.75rem !important;
}
.pl-4 {
  padding-left: 1rem !important;
}
.pl-5 {
  padding-left: 1.25rem !important;
}
.pr-4 {
  padding-right: 1rem !important;
}
.pt-8 {
  padding-top: 2rem !important;
}
.pl-28 {
  padding-left: 7rem !important;
}
.pl-14 {
  padding-left: 3.5rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pt-5 {
  padding-top: 1.25rem !important;
}
.pl-10 {
  padding-left: 2.5rem !important;
}
.pl-px {
  padding-left: 1px !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pb-8 {
  padding-bottom: 2rem !important;
}
.pr-\[7px\] {
  padding-right: 7px !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.font-sans {
  font-family: Helvetica Neue, Arial, Hiragino Kaku Gothic ProN, Noto Sans, Meiryo, sans-serif !important;
}
.text-xl {
  font-size: 1.25rem !important;
  line-height: 30px !important;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 21px !important;
}
.text-2xl {
  font-size: 1.5rem !important;
  line-height: 36px !important;
}
.text-lg {
  font-size: 1.125rem !important;
  line-height: 27px !important;
}
.text-4xl {
  font-size: 1.875rem !important;
  line-height: 45px !important;
}
.text-xs {
  font-size: 0.75rem !important;
  line-height: 18px !important;
}
.text-2xs {
  font-size: 0.625rem !important;
  line-height: 15px !important;
}
.text-3xs {
  font-size: 0.5rem !important;
  line-height: 12px !important;
}
.text-base {
  font-size: 1rem !important;
  line-height: 24px !important;
}
.text-\[7rem\] {
  font-size: 7rem !important;
}
.text-\[10px\] {
  font-size: 10px !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-normal {
  font-weight: 400 !important;
}
.leading-8 {
  line-height: 2rem !important;
}
.leading-5 {
  line-height: 1.25rem !important;
}
.leading-none {
  line-height: 1 !important;
}
.leading-normal {
  line-height: 1.5 !important;
}
.leading-9 {
  line-height: 2.25rem !important;
}
.text-orange-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(243 152 0 / var(--tw-text-opacity)) !important;
}
.text-blue-10 {
  --tw-text-opacity: 1 !important;
  color: rgb(67 188 205 / var(--tw-text-opacity)) !important;
}
.text-error {
  --tw-text-opacity: 1 !important;
  color: rgb(244 67 54 / var(--tw-text-opacity)) !important;
}
.text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}
.text-gray-60 {
  --tw-text-opacity: 1 !important;
  color: rgb(197 197 197 / var(--tw-text-opacity)) !important;
}
.text-gray-110 {
  --tw-text-opacity: 1 !important;
  color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
}
.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-success {
  --tw-text-opacity: 1 !important;
  color: rgb(67 188 205 / var(--tw-text-opacity)) !important;
}
.text-red-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(244 67 54 / var(--tw-text-opacity)) !important;
}
.text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}
.text-blue-20 {
  --tw-text-opacity: 1 !important;
  color: rgb(65 140 227 / var(--tw-text-opacity)) !important;
}
.text-gray-90 {
  --tw-text-opacity: 1 !important;
  color: rgb(102 102 102 / var(--tw-text-opacity)) !important;
}
.text-red-40 {
  --tw-text-opacity: 1 !important;
  color: rgb(229 115 115 / var(--tw-text-opacity)) !important;
}
.text-gray-70 {
  --tw-text-opacity: 1 !important;
  color: rgb(153 153 153 / var(--tw-text-opacity)) !important;
}
.text-gray-40 {
  --tw-text-opacity: 1 !important;
  color: rgb(229 229 229 / var(--tw-text-opacity)) !important;
}
.text-gray-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(204 204 204 / var(--tw-text-opacity)) !important;
}
.text-blue-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
}
.text-gray-80 {
  --tw-text-opacity: 1 !important;
  color: rgb(136 136 136 / var(--tw-text-opacity)) !important;
}
.text-blue-40 {
  --tw-text-opacity: 1 !important;
  color: rgb(100 181 246 / var(--tw-text-opacity)) !important;
}
.overline {
  -webkit-text-decoration-line: overline !important;
          text-decoration-line: overline !important;
}
.placeholder-gray-110::-moz-placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(51 51 51 / var(--tw-placeholder-opacity)) !important;
}
.placeholder-gray-110:-ms-input-placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(51 51 51 / var(--tw-placeholder-opacity)) !important;
}
.placeholder-gray-110::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(51 51 51 / var(--tw-placeholder-opacity)) !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-\[0_2px_0_1px\] {
  --tw-shadow: 0 2px 0 1px !important;
  --tw-shadow-colored: 0 2px 0 1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-gray-70 {
  --tw-shadow-color: #999999 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}
.blur {
  --tw-blur: blur(8px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.transition-transform {
  transition-property: transform !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}
.transition-colors {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}
.\[word-break\:break-word\] {
  word-break: break-word;
}

:root {
  --appbar-h: 64px;
}

html,
body {
  padding: 0 !important;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

* {
  box-sizing: border-box !important;
}

/* stylelint-disable selector-id-pattern, selector-class-pattern */
html, body, body > div:first-child, div#__next, div#__next > div {
  height: 100% !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 246 250 / var(--tw-bg-opacity)) !important;
}

.react-datepicker__month--selected,
.react-datepicker__day--selected {
  /* prettier-ignore */
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 152 0 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.react-datepicker__month-text--keyboard-selected:not(.react-datepicker__month--selected) {
  background-color: unset !important;
  color: unset !important;
}

.react-datepicker__day--outside-month {
  visibility: hidden !important;
}

.first\:border-l-0:first-child {
  border-left-width: 0px !important;
}

.hover\:bg-orange-40:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 173 51 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-30:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-brown-30:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(198 183 161 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-brown-60:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(175 141 85 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-20:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 236 235 / var(--tw-bg-opacity)) !important;
}

.hover\:text-gray-40:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(229 229 229 / var(--tw-text-opacity)) !important;
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(96 165 250 / var(--tw-text-opacity)) !important;
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none !important;
}

.disabled\:bg-brown-20:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(232 226 217 / var(--tw-bg-opacity)) !important;
}

.disabled\:text-red-30:disabled {
  --tw-text-opacity: 1 !important;
  color: rgb(238 170 170 / var(--tw-text-opacity)) !important;
}

.disabled\:opacity-25:disabled {
  opacity: 0.25 !important;
}

